import React from "react";
import "./Break.css";

function Break() {
  return (
    <svg
      className="break"
      width="250"
      height="35"
      viewBox="0 0 404 34"
      fill="#b02000"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path opacity="0.2" d="M16 13H6L0 21H16H32H42L48 13H32H16Z"></path>
      <path opacity="0.5" d="M72 13H62L56 21H72H88H98L104 13H88H72Z"></path>
      <path d="M128 13H118L112 21H128H144H154L160 13H144H128Z"></path>
      <path d="M260 13H250L244 21H260H276H286L292 13H276H260Z"></path>
      <path
        opacity="0.5"
        d="M316 13H306L300 21H316H332H342L348 13H332H316Z"
      ></path>
      <path
        opacity="0.2"
        d="M372 13H362L356 21H372H388H398L404 13H388H372Z"
      ></path>
      <path d="M202.012 0.67749V0.67749C204.004 8.73167 210.293 15.0203 218.347 17.0122V17.0122V17.0122C210.088 18.63 203.63 25.0875 202.012 33.3468V33.3468V33.3468C199.925 25.3467 193.678 19.0991 185.678 17.0122V17.0122V17.0122C193.887 15.3018 200.302 8.88717 202.012 0.67749V0.67749Z"></path>
    </svg>
  );
}

export default Break;
