// src/components/QnA.js
import React, { useState } from "react";
import "./QnA.css";
import { useTranslation } from "react-i18next";

export default function Questions() {
  const { t } = useTranslation();
  // Separate states for each column's active question
  const [activeQuestionLeft, setActiveQuestionLeft] = useState(null);
  const [activeQuestionRight, setActiveQuestionRight] = useState(null);

  const toggleAnswerLeft = (questionId) => {
    setActiveQuestionLeft((prevQuestionId) =>
      prevQuestionId === questionId ? null : questionId
    );
  };

  const toggleAnswerRight = (questionId) => {
    setActiveQuestionRight((prevQuestionId) =>
      prevQuestionId === questionId ? null : questionId
    );
  };

  // Updated FAQ data with concise and accurate answers
  const questionsLeft = [
    {
      id: 1,
      question: t("q1"),
      answer: t("a1"),
    },
    {
      id: 2,
      question: t("q2"),
      answer: t("a2"),
    },
    {
      id: 3,
      question: t("q3"),
      answer: t("a3"),
    },
    {
      id: 4,
      question: t("q4"),
      answer: t("a4"),
    },
  ];

  const questionsRight = [
    {
      id: 5,
      question: t("q5"),
      answer: t("a5"),
    },
    {
      id: 6,
      question: t("q6"),
      answer: t("a6"),
    },
    {
      id: 7,
      question: t("q7"),
      answer: t("a7"),
    },
  ];

  return (
    <section className="QnA" id="faq">
      <div className="container-fluid">
        <div className="row">
          <h2>
            {t("questionstitleblack")} <span>{t("questionstitle")}</span>
          </h2>
        </div>
        <div className="row">
          {/* Left Column: First 4 Questions */}
          <div className="col-md-6 card">
            {questionsLeft.map((q, index) => (
              <div className="question" key={q.id}>
                <h5
                  onClick={() => toggleAnswerLeft(q.id)}
                  data-active={activeQuestionLeft === q.id}
                  aria-expanded={activeQuestionLeft === q.id}
                  aria-controls={`faq-answer-left-${q.id}`}
                  role="button"
                  tabIndex={0}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") toggleAnswerLeft(q.id);
                  }}
                >
                  {q.question}
                  {activeQuestionLeft === q.id ? (
                    <i className="bi bi-dash-lg"></i>
                  ) : (
                    <i className="bi bi-plus-lg"></i>
                  )}
                </h5>
                {activeQuestionLeft === q.id && (
                  <p id={`faq-answer-left-${q.id}`}>{q.answer}</p>
                )}
                {index !== questionsLeft.length - 1 && <hr />}
              </div>
            ))}
          </div>

          {/* Right Column: Last 3 Questions */}
          <div className="col-md-6 card">
            {questionsRight.map((q, index) => (
              <div className="question" key={q.id}>
                <h5
                  onClick={() => toggleAnswerRight(q.id)}
                  data-active={activeQuestionRight === q.id}
                  aria-expanded={activeQuestionRight === q.id}
                  aria-controls={`faq-answer-right-${q.id}`}
                  role="button"
                  tabIndex={0}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") toggleAnswerRight(q.id);
                  }}
                >
                  {q.question}
                  {activeQuestionRight === q.id ? (
                    <i className="bi bi-dash-lg"></i>
                  ) : (
                    <i className="bi bi-plus-lg"></i>
                  )}
                </h5>
                {activeQuestionRight === q.id && (
                  <p id={`faq-answer-right-${q.id}`}>{q.answer}</p>
                )}
                {index !== questionsRight.length - 1 && <hr />}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
