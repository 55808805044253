// src/components/LanguageSwitcher.js
import React from "react";
import { useTranslation } from "react-i18next";
import ReactCountryFlag from "react-country-flag";
import "./LanguageSwitcher.css";

export default function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const languages = [
    { code: "en", name: "English", countryCode: "GB" }, // Use 'US' for American English
    { code: "de", name: "Deutsch", countryCode: "DE" },
    // Add more languages here
  ];

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="language-switcher">
      {languages.map((lang) => (
        <button
          key={lang.code}
          onClick={() => changeLanguage(lang.code)}
          className={`lang-button ${
            i18n.language === lang.code ? "active" : ""
          }`}
          aria-label={lang.name}
        >
          <ReactCountryFlag
            countryCode={lang.countryCode}
            svg
            className="flag-icon"
            aria-label={lang.name}
          />
          <span className="lang-text">{lang.code.toUpperCase()}</span>
        </button>
      ))}
    </div>
  );
}
