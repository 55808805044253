import React from "react";
import "./Footer.css";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useNavigate } from "react-router-dom";

// Social link component
const SocialLink = ({ href, iconClass }) => (
  <li>
    <a href={href} target="_blank" rel="noopener noreferrer">
      <i className={iconClass}></i>
    </a>
  </li>
);

// Footer link component
const FooterLink = ({ href, text }) => (
  <li>
    <a href={href} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  </li>
);

// Array of social links
const socialLinks = [
  //{ href: "https://twitter.com", iconClass: "bi bi-twitter" },
  //{ href: "https://discord.com", iconClass: "bi bi-discord" },
];

// Array of footer links

export default function Footer() {
  const { t } = useTranslation();

  const footerLinks = [
    { href: "/impress", text: t("impress") },
    //{ href: "/", text: t("terms") },
  ];

  return (
    <section className="Footer">
      <div className="container">
        <div className="row">
          {/* Adapted Join Us Section to Survey Participation */}
          <div className="join-us">
            <h1>
              {t("participatetitleblack")} <br />
              <span>{t("participatetitle")}</span>
            </h1>
            <p className="subtitle">{t("participatedesc")}</p>
            <div className="cta">
              <RouterLink
                to="/survey"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("takeTheSurvey")}
              </RouterLink>
            </div>
          </div>

          {/* Social Links */}
          <ul className="social-links">
            {socialLinks.map((link, index) => (
              <SocialLink
                key={index}
                href={link.href}
                iconClass={link.iconClass}
              />
            ))}
          </ul>

          {/* Footer Links */}
          <ul className="footer-links">
            {footerLinks.map((link, index) => (
              <FooterLink key={index} href={link.href} text={link.text} />
            ))}
          </ul>

          {/* Copy and Credit */}
          <p className="copy">
            © {new Date().getFullYear()} WineDAO. {t("allrights")}
          </p>
          <p className="credit">
            {t("codedwith")}
            <a
              href="https://www.x.com/acgueler"
              target="_blank"
              rel="noopener noreferrer"
            >
              @acgueler
            </a>
          </p>
        </div>
      </div>
    </section>
  );
}
