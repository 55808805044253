// src/components/ImpressPage.js
import React from "react";
import { useTranslation } from "react-i18next";

const ImpressPage = () => {
  const { t } = useTranslation();

  return (
    <div className="impress-page" style={{ padding: "20px" }}>
      <section className="container-fluid">
        <div className="content">
          <div className="row">
            {/* Company Information */}
            <div className="col-md-12 mb-4">
              <h3>{t("companyInfoTitle")}</h3>
              <p>{t("companyName")}</p>
              <p>{t("companyAddress")}</p>
              <p>{t("companyCityCountry")}</p>
              <p>{t("companyEmail")}</p>
            </div>
            {/* Legal Disclaimer */}
            <div className="col-md-12 mb-4">
              <h3>{t("legalDisclaimerTitle")}</h3>
              <p>{t("legalDisclaimerVersion")}</p>
              <p dangerouslySetInnerHTML={{ __html: t("legalDisclaimer1") }} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ImpressPage;
