// src/components/Team.js
import React from "react";
import "./Team.css";
import { useTranslation } from "react-i18next";

// Importing React Icons for social links
import { BiLogoTwitter, BiEnvelope, BiLogoDiscord } from "react-icons/bi";
import { t } from "i18next";

// Updated teamMembers array as a flat array

export default function Team() {
  const { t } = useTranslation();

  const teamMembers = [
    {
      name: "Dr. Christian Hitz",
      jobTitle: t("lecturer"),
      image: require("../images/christianhitz.jpeg"),
      twitter: "",
      email: "",
      discord: "",
    },
    {
      name: "Ali Cem Güler",
      jobTitle: t("teamlead"),
      image: require("../images/alicemgueler.jpeg"),
      twitter: "",
      email: "",
      discord: "",
    },
    {
      name: "Joel Egli",
      jobTitle: t("student"),
      image: require("../images/joelegli.jpeg"),
      twitter: "",
      email: "",
      discord: "",
    },
    {
      name: "Artan Perkola",
      jobTitle: t("student"),
      image: require("../images/artanperkola.jpeg"),
      twitter: "",
      email: "",
      discord: "",
    },
    {
      name: "Samuel Jucker",
      jobTitle: t("student"),
      image: require("../images/samueljucker.jpg"),
      twitter: "",
      email: "",
      discord: "",
    },
  ];
  const numberOfRows = 2;
  const membersPerRow = Math.ceil(teamMembers.length / numberOfRows);

  const rows = [];
  for (let i = 0; i < numberOfRows; i++) {
    const startIndex = i * membersPerRow;
    const endIndex = startIndex + membersPerRow;
    const rowMembers = teamMembers.slice(startIndex, endIndex);
    rows.push(rowMembers);
  }

  return (
    <section className="team">
      <div className="container-fluid">
        <h1>
          {t("meetourteamtitleblack")} <span>{t("meetourteamtitle")}</span>
        </h1>
        <p className="subtitle">{t("meetourteamdesc")}</p>
        {/* Team Members Display */}
        {rows.map((row, rowIndex) => (
          <div className="row justify-content-center mb-4" key={rowIndex}>
            {row.map((member, index) => (
              <div className="col-md-3 col-sm-6 team-member" key={index}>
                <div className="card">
                  <img
                    src={member.image}
                    className="card-img-top"
                    alt={`${member.name}`}
                    style={{
                      height: "325px",
                      objectFit: "fit",
                    }}
                  />
                  <div className="card-body text-center">
                    <h5 className="card-title">{member.name}</h5>
                    <p className="card-text">{member.jobTitle}</p>
                    <div className="social-links">
                      {member.twitter && (
                        <a
                          href={`https://twitter.com/${member.twitter}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label={`${member.name}'s Twitter`}
                        >
                          <BiLogoTwitter />
                        </a>
                      )}
                      {member.email && (
                        <a
                          href={`mailto:${member.email}`}
                          aria-label={`${member.name}'s Email`}
                        >
                          <BiEnvelope />
                        </a>
                      )}
                      {member.discord && (
                        <a
                          href={`https://discord.com/users/${member.discord}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label={`${member.name}'s Discord`}
                        >
                          <BiLogoDiscord />
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </section>
  );
}
