import React from "react";
import "./Why.css";
import Agriculture from "../images/agriculture.svg";
import { useTranslation } from "react-i18next";

export default function Why() {
  const { t } = useTranslation();

  return (
    <section className="why">
      <div className="container-fluid">
        <div className="content">
          <h2>
            {t("whytitleblack")} <span>{t("whytitle")}</span>
          </h2>
          <div className="row">
            {/* Left Column */}
            <div className="col-md-4">
              {/* Decentralized Governance */}
              <div className="card mb-3">
                <div className="row g-0">
                  <div className="col-md-4">
                    <div className="card-icon">
                      <i className="bi bi-people-fill"></i>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-title">{t("community")}</h5>
                      <p className="card-text">{t("communitydesc")}</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Profitable Agriculture Investments */}
              <div className="card mb-3">
                <div className="row g-0">
                  <div className="col-md-4">
                    <div className="card-icon">
                      <i className="bi bi-bar-chart-fill"></i>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-title">{t("investment")}</h5>
                      <p className="card-text">{t("investmentdesc")}</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Community Ownership */}
              <div className="card mb-3">
                <div className="row g-0">
                  <div className="col-md-4">
                    <div className="card-icon">
                      <i className="bi bi-hand-thumbs-up-fill"></i>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-title">{t("collective")}</h5>
                      <p className="card-text">{t("collectivedesc")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Middle Column with Image */}
            <div className="col-md-4">
              <div className="row justify-content-center">
                <div className="col-md-12">
                  <img
                    className="img-fluid center-img"
                    src={Agriculture}
                    alt="Community Cellar"
                  />
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div className="col-md-4">
              {/* Exclusive Experiences */}
              <div className="card mb-3">
                <div className="row g-0">
                  <div className="col-md-4">
                    <div className="card-icon">
                      <i className="bi bi-award-fill"></i>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-title">{t("shared")}</h5>
                      <p className="card-text">{t("shareddesc")}</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Sustainable Business Growth */}
              <div className="card mb-3">
                <div className="row g-0">
                  <div className="col-md-4">
                    <div className="card-icon">
                      <i className="bi bi-building-fill"></i>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-title">{t("growth")}</h5>
                      <p className="card-text">{t("growthdesc")}</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Transparent Operations */}
              <div className="card mb-3">
                <div className="row g-0">
                  <div className="col-md-4">
                    <div className="card-icon">
                      <i className="bi bi-eye-fill"></i>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-title">{t("transparent")}</h5>
                      <p className="card-text">{t("transparentdesc")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
